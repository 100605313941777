import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getCourse = createAsyncThunk('getCourseData',async(courseId)=>{

    const request = await fetch(`${process.env.REACT_APP_VERSEL_API}/api/students/courses/getCourseById/${courseId}`).then(response => response.json()).then(resulte => {
        console.log(resulte);
        return resulte;
    }).catch(error=>{
        return error;
    })

    return request;

});

export const getPlan = createAsyncThunk('getCourseData',async(planId)=>{

    const request = await fetch(`${process.env.REACT_APP_VERSEL_API}/api/student/plans/getPlanById/${planId}`).then(response => response.json()).then(resulte => {
        console.log(resulte);
        return resulte;
    }).catch(error=>{
        return error;
    })

    return request;

});


const getCourseByIdSlicer = createSlice({
    name : "course/getcoursebyid",
    initialState:{
        loading : false,
        data : null,
        error : null,
    },

    extraReducers: (builder)=>{
        builder.addCase(getCourse.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getCourse.fulfilled,(state,action)=>{
            state.loading = false;
             if(action.payload.message === "fetched successfully"){
                state.data = action.payload.data
                state.error = null;
             }else{
                state.data = null
                state.error = action.payload.message;
             }   
        
        });
        builder.addCase(getCourse.rejected,(state,action)=>{
            state.loading = false;
             state.data = null;
             state.error = action.payload.message;
        
        });
    }
});

export default getCourseByIdSlicer.reducer
