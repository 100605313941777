import "../../styles/loading.css";
import lightLoago from "../../Images/logoGray.png";

const Loading = () => {
  return (
    <section className="load">
      <div className="container">
        {/* <img src={lightLoago} alt="Loading Logo" /> */}
        <div className="loader">
          <div className="outer"></div>
          <div className="middle"></div>
          <div className="inner"></div>
        </div>
      </div>
    </section>
  );
};
export default Loading;
