import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const updatePersonalImageApi = createAsyncThunk(
  "updatepersonalImage",
  async (personaimage) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;
    const formData = new FormData();
    formData.append("image", personaimage);


    console.log(personaimage);

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/update-personal-image`,
      {
        method: "POST",
        headers: {
        //   "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`,
        },
        body: formData,
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const updatePersonalImageSlicer = createSlice({
  name: "profile/updatePersonalImage",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updatePersonalImageApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePersonalImageApi.fulfilled, (state, action) => {
      state.loading = false;
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message === "Data Updated Successfully"
      ) {
        state.response = action.payload;
      }
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message === "Invalid user data"
      ) {
        state.error = action.payload.data[0].msg;
      } 
    });
    builder.addCase(updatePersonalImageApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error
      
    });
  },
});

export default updatePersonalImageSlicer.reducer;
