import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../services/LogoutFunction";

export const getStudentCourseApi = createAsyncThunk(
  "getStudentCourese",
  async () => {
    const tokenData = await JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/courses/getMyCourses`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
        return error;
      });
    return request;
  }
);

const getStudentCoursesSlicer = createSlice({
  name: "courses/getstudentCourse",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getStudentCourseApi.pending, (state) => {
      state.loading = true;
      state.date = null;
      state.error = null;
    });
    builder.addCase(getStudentCourseApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
        LogoutFunction();
      }
      if (action.payload.data != []) {
        // state.date = action.payload.data;
        state.date = action.payload;
        state.error = null;
      } else {
        state.date = null;
        state.error = "No courses found";
      }
    });
    builder.addCase(getStudentCourseApi.rejected, (state, action) => {
      state.loading = false;
      state.date = null;

      state.error = action.payload;
    });
  },
});

export default getStudentCoursesSlicer.reducer;
