import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
const generateDeviceId = () => {
  const screenResolution = `${window.screen.width}x${window.screen.height}`;
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const language = navigator.language || navigator.userLanguage;
  const deviceType = /Mobi|Tablet|iPad|iPhone|Android/.test(navigator.userAgent)
    ? "Mobile"
    : "Desktop";

  // You can add more factors as needed

  return `${screenResolution}-${timezone}-${language}-${deviceType}-${navigator.platform}`;
};

export const createUser = createAsyncThunk(
  "createUser",
  async (userCredintianals) => {
    const uderIdData = generateDeviceId();
    const userDataFromApi = await fetch("https://ipapi.co/json").then((res) =>
      res.json()
    );
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/signup`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          name: userCredintianals.name,
          mobile: userCredintianals.mobile,
          title: "new student",
          email: userCredintianals.email,
          image: "",
          password: userCredintianals.password,
          role: "student",
          ipData: { ...userDataFromApi, uderIdData },
          googleId: userCredintianals.googleId,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => error);

    const response = request;

    return response;
  }
);

const addUserSlicer = createSlice({
  name: "addUser",
  initialState: {
    loading: false,
    addUser: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(createUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(createUser.fulfilled, (state, action) => {
      state.loading = false;
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message === " User created Successfully"
      ) {
        state.addUser = action.payload;
      }
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message === "Invalid user data"
      ) {
        state.error = action.payload.data[0].msg;
      } else {
        state.error = action.payload.message;
      }
    });
    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.addUser = null;
      action.payload && action.payload.message
        ? (state.error = action.payload.message)
        : (state.error = "Something went wrong");
    });
  },
});

export default addUserSlicer.reducer;
