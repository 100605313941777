import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../../services/LogoutFunction";

export const getScoreDataApi = createAsyncThunk(
  "getScoreDara",
  async (quizeId) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/quize/getScoreData/${quizeId}`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        console.log(resulet);
        return resulet;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return request;
  }
);

const getQuizeScoreDataSlicer = createSlice({
  name: "quize/getScore",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getScoreDataApi.pending, (state) => {
      state.loading = true;
      state.date = null;
      state.error = null;
    });
    builder.addCase(getScoreDataApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;

        LogoutFunction();
      }
      if (action.payload.message === "Quize Score Data") {
        state.date = action.payload.data;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(getScoreDataApi.rejected, (state, action) => {
      state.loading = false;
      state.date = null;
      state.error = action.payload;
    });
  },
});

export default getQuizeScoreDataSlicer.reducer;
