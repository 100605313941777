// import { createSlice } from "@reduxjs/toolkit";

// const TheamSlicer = createSlice({
//   name: "theam",
//   initialState: {
//     them: "light",
//   },
//   reducers: {
//     setTheam: (state) => {
//       const localTheam = localStorage.getItem("them");
//       if (localTheam) {
//         state.them = localTheam;
//       } else {
//         localStorage.setItem("them", "light");
//       }
//       document
//         .getElementsByTagName("body")[0]
//         .setAttribute("them-data", state.them);
//     },

//     ChangeTheam: (state) => {

//       state.them = state.them === "light" ? "dark" : "light";
//       localStorage.setItem("them", state.them);
//       document
//         .getElementsByTagName("body")[0]
//         .setAttribute("them-data", state.them);
//     },
//   },
// });

// export const { ChangeTheam,setTheam } = TheamSlicer.actions;
// export default TheamSlicer.reducer;

import { createSlice } from "@reduxjs/toolkit";

const setUserCountryCodeSlicer = createSlice({
  name: "setCountryCode",
  initialState: {
    code: null,
  },
  reducers: {
    setUserCountryCodeFunc: (state , action) => {
      console.log(state)
      console.log(action.payload)
      state.code = action.payload
    },
  },
});

export const { setUserCountryCodeFunc } = setUserCountryCodeSlicer.actions;
export default setUserCountryCodeSlicer.reducer;
