import { createSlice } from "@reduxjs/toolkit";

const authIsLoginSlicer =  createSlice({
    name:'isLogin',
    initialState : {
        isLogin:false
    },
    reducers :{
        LogedIn(state){
             
            state.isLogin = true;
        },
        LogedOut(state){
            state.isLogin = false;
            localStorage.removeItem("userData");
        },
    
    }  

})

export default authIsLoginSlicer.reducer;

export const {LogedIn,LogedOut} = authIsLoginSlicer.actions