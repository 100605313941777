import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getInstractorsApi = createAsyncThunk('getAllInstractors',
async()=>{
    const request = await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/students/users/teachers`
      )
        .then((response) => response.json())
        .then((resulet) => {
          return resulet;
        })
        .catch((error) => {
         
          return error;
        });
      return request;
})

  


const getAllInstractorsSlicer = createSlice({
    name : 'users/instractors/getAll',
    initialState:{
        loading :false,
        data : null,
        error:null
    },
    extraReducers: (builder) => {
        builder.addCase(getInstractorsApi.pending, (state) => {
          state.loading = true;
          state.date = null;
          state.error = null;
        });
        builder.addCase(getInstractorsApi.fulfilled, (state, action) => {
          state.loading = false;
          if (action.payload.message === "fetched successfully") {
            state.date = action.payload.data;
            state.error = null;
          } else {
            state.date = null;
            state.error = action.payload.message;
          }
        });
        builder.addCase(getInstractorsApi.rejected, (state, action) => {
          state.loading = false;
          state.date = null;
          state.error = action.payload.message;
        });
      },
});

export default getAllInstractorsSlicer.reducer
