import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

export const sendFeedbackFunc = createAsyncThunk(
    "feedback/send",
    async (data) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_VERSEL_API}/api/student/feedbacks` , data);
            console.log(response)
            return response.data
        }
        catch (error) {
            return error
        }
    }
)

const sendFeedbackSlicer = createSlice({
    name : "feedback/send",
    initialState : {
        Loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(sendFeedbackFunc.pending , (state) => {
            state.Loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(sendFeedbackFunc.fulfilled , (state , action) => {
            state.Loading = false
            state.data = action.payload
            state.error = null
        })
        builder.addCase(sendFeedbackFunc.rejected , (state , action) => {
            state.Loading = null
            state.data = null
            state.error = action.payload
        })
    }
})
export default sendFeedbackSlicer.reducer;