import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../../services/LogoutFunction";

export const getLessonExamFunc = createAsyncThunk(
  "getQuize",
  async (lessonId) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/lessons/start-exam/${lessonId}`,
      {
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        console.log(resulet);
        return resulet;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return request;
  }
);

const getLessonExamSlicer = createSlice({
  name: "quize/getQuize",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getLessonExamFunc.pending, (state) => {
      state.loading = true;
      state.date = null;
      state.error = null;
    });
    builder.addCase(getLessonExamFunc.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;
       
        LogoutFunction();
      }
      if (action.payload.data != []) {
        state.date = action.payload.data;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(getLessonExamFunc.rejected, (state, action) => {
      state.loading = false;
      state.date = null;
      state.error = action.payload;
    });
  },
});

export default getLessonExamSlicer.reducer;
