import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { H } from "highlight.run";

export const CerateCertificateApi = createAsyncThunk(
  "CreateCertificate",
  async (data) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/certificate/create-certificate`,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
        body: data,
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
      //  H.consumeError(error, error.message);
        return error;
      });
    return request;
  }
);

const CreateCertificateSlicer = createSlice({
  name: "certificate/create",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(CerateCertificateApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(CerateCertificateApi.fulfilled, (state, action) => {
      state.loading = false;
      state.date = action.payload;
    });
    builder.addCase(CerateCertificateApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default CreateCertificateSlicer.reducer;
