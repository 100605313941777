import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getSingleDiplomaDataFromApi = createAsyncThunk(
  "getSingleDiploma",
  async (diplomaId) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/student/diplome/get-by-id/${diplomaId}`
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
        return error;
      });
    return request;
  }
);

const getSingleDiplomaSclicer = createSlice({
  name: "diplome/getsingle",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleDiplomaDataFromApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleDiplomaDataFromApi.fulfilled, (state, action) => {
      state.loading = false;
      state.date = action.payload;
    });
    builder.addCase(getSingleDiplomaDataFromApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getSingleDiplomaSclicer.reducer;
