import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const forgetPasswordApi = createAsyncThunk(
  "forgetpassword",
  async (userCredintianals) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/forgetPassword`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          email: userCredintianals.email,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => error);

    return request;
  }
);

const forgetPasswordSlicer = createSlice({
  name: "users/forget-password",
  initialState: {
    loading: false,
    res: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(forgetPasswordApi.pending,(state)=>{
        state.loading = true;
        state.res = null;
        state.error = null

    });
    builder.addCase(forgetPasswordApi.fulfilled,(state,action)=>{
        if (action.payload.message === "email send") {
            state.loading = false;
            state.res = action.payload.message;
            state.error = null;
        } else {
            state.loading = false;
            state.res = null;
            state.error = action.payload.message;
            
        }
        console.log(action.payload);


    });
    builder.addCase(forgetPasswordApi.rejected,(state,action)=>{
        state.loading = false;
        state.res = null;
        state.error = action.payload.message;

    });
  },
});

export default forgetPasswordSlicer.reducer;
