import { configureStore } from "@reduxjs/toolkit";
import langReducer from "./langSlicer";
import userDataSlicer from "./userDataSlicer";
import addedUserSlicer from "./addedUserSlicer";
import TheamSlicer from "./TheamSlicer";
import AuthIsLoginSlicer from "./AuthIsLoginSlicer";
import loginWithGoogleSlicer from "./user/loginWithGoogleSlicer";
import createWithGoogleSlicer from "./user/createWithGoogleSlicer";


import getAllCoursesSlicer from "./getAllCoursesSlicer";
import getCourseByIdSlicer from "./getCourseByIdSlicer";
import getAllInstractorsSlicer from "./getAllInstractorsSlicer";
import getInstractorByIdSlicer from "./getInstractorByIdSlicer";
import contactFormSclicer from "./contactFormSclicer";
import joinInstrsctorFormSclicer from "./joinInstrsctorFormSclicer";
import paymentPaymobSlicer from "./paymentPaymobSlicer";
import getStudentCoursesSlicer from "./getStudentCoursesSlicer";
import getAllLessonsSlicer from "./getAllLessonsSlicer";
import getSingleLessonsSlicer from "./getSingleLessonsSlicer";
import forgetPasswordSlicer from "./forgetPasswordSlicer";
import resetPasswordSlicer from "./resetPassowrdSlicer";

//Diplomes
import getAllDiplomesSlicer from "./diplomes/getAllDiplomesSlicer";
import getSingleDiplomaSclicer from "./diplomes/getSingleDiplomaSclicer";
import diplomaPaymentPaymobSlicer from "./diplomes/diplomaPaymentPaymobSlicer";


//Quize
import getQuizeSlice from "./quize/getQuizeSlice";
import getSingleQuizeDataSlicer from "./quize/getSingleQuizeDataSlicer";
import getQuestionQuizeDataSlicer from "./quize/getQuestionQuizeDataSlicer";
import sendAnswersSlicers from "./quize/sendAnswersSlicers";
import getQuizeScoreDataSlicer from "./quize/getQuizeScoreDataSlicer";

//Certificated
import CertificateSlicer from "./user/CertificateSlicer";
import CreateCertificateSlicer from "./user/CreateCertificateSlicer";

//promocode
import checkPromocodeSlicer from "./promocode/checkPromocodeSlicer";

//Profile
import updatePersonalInfoSlicer from "./profile/updatePersonalInfoSlicer";
import getStudentDataSlice from "./profile/getStudentDataSlice";
import updatePersonalImageSlicer from "./profile/updatePersonalImageSlicer";
import updatePasswordSlicer from "./profile/updatePasswordSlicer";

//Plans
import getAllPlansSlicer from './getAllPlansSlicer'

//Reels
import getFreeReelsSlicer from "./getFreeReelsSlicer";
import getReelsSlicer from "./getFreeReelsSlicer";


//jobs
import getJobsSlicer from "./jobs/GetJobsSlicer";
import getSingleJobSlicer from "./jobs/GetSingleJobSlicer";
import postJobRequestSlicer from "./jobs/PostJobRequest";

//feedback
import sendFeedbackSlicer from "./feedback/SendFeedbackSlicer"
import getFeedbacksSlicer from "./feedback/GetFeedbacksSlicer"
import ShowFeedbackFormSlicer from "./feedback/ShowFeedbackFormSlicer"

//lessons
import sendCompletingLessonSlicer from "./lessons/SendCompletingLessonSlicer"
import getLessonExamSlicer from "./lessons/GetLessonExamSlicer"

//settings
import getSettingsSlicer from "./settings/GetSettingsSlicer"
import setUserCountryCodeSlicer from "./user/SetUserCountryCodeSlicer"
import getUserLoacationSlicer from "./user/GetUserLoacationSlicer"

//consultants
import getAllConsultantsSlicer from "./GetAllConsultantsSlicer"
import getSingleConsultantSlicer from "./GetSingleConsultantSlicer"
import consultationPaymentMob from "./ConsultationPaymentMob"
import consultationPaymentPaymobSlicer from "./ConsultationPaymentPaymobSlicer"




const store = configureStore({
  reducer: {
    lang: langReducer,
    theam: TheamSlicer,

    userData: userDataSlicer,
    createUser: addedUserSlicer,
    auth: AuthIsLoginSlicer,
    forgetPassword: forgetPasswordSlicer,
    resetPassword: resetPasswordSlicer,

    getCourses: getAllCoursesSlicer,
    getCourseById: getCourseByIdSlicer,
    getStudentCourses: getStudentCoursesSlicer,
    getCoursesLessons: getAllLessonsSlicer,
    getSingleLessons: getSingleLessonsSlicer,

    //diplome
    getAllDiplomesSlicer: getAllDiplomesSlicer,
    getSingleDiplomaSclicer: getSingleDiplomaSclicer,

    //plans
    getAllPlansSlicer: getAllPlansSlicer,

    //Reels
    getFreeReelsSlicer: getFreeReelsSlicer,
    getReelsSlicer: getReelsSlicer,


    //Quize
    getQuizeSlice,
    getSingleQuizeDataSlicer,
    getQuestionQuizeDataSlicer,
    sendAnswersSlicers,
    getQuizeScoreDataSlicer,

    getInstractors: getAllInstractorsSlicer,
    getInstractorById: getInstractorByIdSlicer,

    contactForm: contactFormSclicer,
    joinForm: joinInstrsctorFormSclicer,

    //payment
    paymobpayment: paymentPaymobSlicer,
    diplomaPaymentPaymobSlicer: diplomaPaymentPaymobSlicer,

    //promocode
    checkPromocodeSlicer,

    //Profile
    updatePersonalInfoSlicer,
    getStudentDataSlice,
    updatePersonalImageSlicer,
    updatePasswordSlicer,
    loginWithGoogleSlicer,
    createWithGoogleSlicer,

    //Certificate
    CertificateSlicer,
    CreateCertificateSlicer,

    // jobs
    getJobsSlicer,
    getSingleJobSlicer,
    postJobRequestSlicer,

    //feedbck
    sendFeedbackSlicer,
    getFeedbacksSlicer,
    ShowFeedbackFormSlicer,
    sendCompletingLessonSlicer,

    getLessonExamSlicer,
    
    getSettingsSlicer,
    setUserCountryCodeSlicer,
    getUserLoacationSlicer,

    //consultants
    getAllConsultantsSlicer,
    getSingleConsultantSlicer,
    consultationPaymentMob,
    consultationPaymentPaymobSlicer,
  },
});

export default store;
