import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getSingleInstractorsDataApi = createAsyncThunk('getInstractorData',async(instId)=>{

    const request = await fetch(`${process.env.REACT_APP_VERSEL_API}/api/students/users/getTeacherById/${instId}`).then(response => response.json()).then(resulte => {
        return resulte;
    }).catch(error=>{
        return error;
    })
    return request;

});



const getInstractorByIdSlicer = createSlice({
    name : 'users/instractors/getInstractorById',
    initialState:{
        loading : false,
        data : null,
        error : null,
    },

    extraReducers: (builder)=>{
        builder.addCase(getSingleInstractorsDataApi.pending,(state)=>{
            state.loading = true;
        });
        builder.addCase(getSingleInstractorsDataApi.fulfilled,(state,action)=>{
            state.loading = false;
             if(action.payload.message === "fetched successfully"){
                state.data = action.payload.data
                state.error = null;
             }else{
                state.data = null
                state.error = action.payload.message;
             }   
        
        });
        builder.addCase(getSingleInstractorsDataApi.rejected,(state,action)=>{
            state.loading = false;
             state.data = null;
             state.error = action.payload.message;
        
        });
    }
});

export default getInstractorByIdSlicer.reducer
