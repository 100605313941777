import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getFeedbacksFunc = createAsyncThunk(
    "feedback/get",
    async () => {
        const request = await fetch(
          `${process.env.REACT_APP_VERSEL_API}/api/student/feedbacks`
        )
          .then((response) => response.json())
          .then((resulet) => {
            console.log(resulet);
            return resulet.data;
          })
          .catch((error) => {
            //H.consumeError(error,"this is test error mes");
            return error;
          });
        return request;
      }
)

const getFeedbacksSlicer = createSlice({
    name : "feedback/get",
    initialState : {
        Loading : null,
        data : null,
        error : null
    },
    reducers : {},
    extraReducers : (builder) => {
        builder.addCase(getFeedbacksFunc.pending , (state) => {
            state.Loading = true
            state.data = null
            state.error = null
        })
        builder.addCase(getFeedbacksFunc.fulfilled , (state , action) => {
            state.Loading = false
            state.data = action.payload
            state.error = null
        })
        builder.addCase(getFeedbacksFunc.rejected , (state , action) => {
            state.Loading = null
            state.data = null
            state.error = action.payload
        })
    }
})
export default getFeedbacksSlicer.reducer;