import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import Swal from "sweetalert2";

export const postJobRequestFunc = createAsyncThunk(
  "post job request",
  async (data) => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_VERSEL_API}/api/student/carrers`,
        data
      );
      return response.data;
    } catch (error) {
      Swal.fire({
        title: "Error",
        text: error.message, // Access error message using error.message
        icon: "error"
      });
      throw error; // Rethrow the error so that the rejection is propagated to the caller
    }
  }
);

const postJobRequestSlicer = createSlice({
  name: "jobs/postRequest",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(postJobRequestFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(postJobRequestFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.date = action.payload;
    });
    builder.addCase(postJobRequestFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default postJobRequestSlicer.reducer;
