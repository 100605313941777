import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getSettingsFunc = createAsyncThunk('getSettings',
async()=>{
    const request = await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/settings`
      )
        .then((response) => response.json())
        .then((resulet) => {
          return resulet;
        })
        .catch((error) => {
         
          return error;
        });
      return request;
})

  


const getSettingsSlicer = createSlice({
    name : 'settings/get',
    initialState:{
        loading :false,
        data : null,
        error:null
    },
    extraReducers: (builder) => {
        builder.addCase(getSettingsFunc.pending, (state) => {
          state.loading = true;
          state.data = null;
          state.error = null;
        });
        builder.addCase(getSettingsFunc.fulfilled, (state, action) => {
          state.loading = false;
          state.data = action.payload;
          // if (action.payload.message === "fetched successfully") {
          //   state.error = null;
          // } else {
          //   state.data = null;
          //   state.error = action.payload.message;
          // }
        });
        builder.addCase(getSettingsFunc.rejected, (state, action) => {
          state.loading = false;
          state.data = null;
          state.error = action.payload.message;
        });
      },
});

export default getSettingsSlicer.reducer
