import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
// import { H } from "highlight.run";

export const getUserLoacationFunc = createAsyncThunk(
  "user/getlocation",
  async ({latitude , longitude}) => {
    const request = await axios.get(`https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}`);
    console.log(request)
    return request.data
  }
);

const getUserLoacationSlicer = createSlice({
  name: "user/getlocation",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getUserLoacationFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getUserLoacationFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(getUserLoacationFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = true;
    });
  },
});

export default getUserLoacationSlicer.reducer;
