import { createSlice } from "@reduxjs/toolkit";

const langSlicer = createSlice({
  name: "language",
  initialState: {
    lang: "ar",
    consurancy: "جنيها",
  },
  reducers: {
    setLang: (state) => {
      const localLang = localStorage.getItem("lang");
      if (localLang) {
        state.lang = localLang;
        if (state.lang === "ar") {
          state.consurancy = "جنيها";
          document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
          document
            .getElementsByTagName("html")[0]
            .setAttribute("lang", state.lang);
          localStorage.setItem("lang", state.lang);
        } else {
          state.consurancy = "EG";
          document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
          document
            .getElementsByTagName("html")[0]
            .setAttribute("lang", state.lang);
          localStorage.setItem("lang", state.lang);
        }
      } else {
        document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
        document
          .getElementsByTagName("html")[0]
          .setAttribute("lang", state.lang);
        localStorage.setItem("lang", state.lang);
        state.consurancy = "جنيها";
      }
    },

    setToArabic: (state) => {
      state.lang = "ar";
      state.consurancy = "جنيها";
      document.getElementsByTagName("html")[0].setAttribute("dir", "rtl");
      document.getElementsByTagName("html")[0].setAttribute("lang", state.lang);
      localStorage.setItem("lang", state.lang);
    },
    setToEnglish: (state) => {
      state.lang = "en";
      state.consurancy = "EG";
      document.getElementsByTagName("html")[0].setAttribute("dir", "ltr");
      document.getElementsByTagName("html")[0].setAttribute("lang", state.lang);
      localStorage.setItem("lang", state.lang);
    },
  },
});

export const { setToArabic, setToEnglish, setLang } = langSlicer.actions;
export default langSlicer.reducer;
