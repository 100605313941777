import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const checkPromocodeApi = createAsyncThunk(
  "checkPromocode",

  async (promocode) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/promocode/checkPromocode`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`,
        },
        body: JSON.stringify({ promocode }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => {
        return error;
      });
  }
);

const checkPromocodeSlicer = createSlice({
  name: "promocode/check-promocode",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(checkPromocodeApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkPromocodeApi.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
    });
    builder.addCase(checkPromocodeApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default checkPromocodeSlicer.reducer;
