import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../../services/LogoutFunction";

export const sendAnswersApi = createAsyncThunk("getQuize", async (data) => {
  console.log(data.answers);
  const tokenData = JSON.parse(localStorage.getItem("userData")).token;
  const request = await fetch(
    `${process.env.REACT_APP_VERSEL_API}/api/students/quize/correct-answers/${data.quizeId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${tokenData}`,
      },
      body: JSON.stringify(data.answers)
    }
  )
    .then((response) => response.json())
    .then((resulet) => {
      console.log(resulet);
      return resulet;
    })
    .catch((error) => {
      console.log(error);
      return error;
    });
  return request;
});

const sendAnswersSlicers = createSlice({
  name: "quize/sendAnswer",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(sendAnswersApi.pending, (state) => {
      state.loading = true;
      state.date = null;
      state.error = null;
    });
    builder.addCase(sendAnswersApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "jwt expired") {
        state.error = action.payload.message;

        LogoutFunction();
      }
      if (action.payload.message === "Answer Submitted Successfuly") {
        state.date = action.payload;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(sendAnswersApi.rejected, (state, action) => {
      state.loading = false;
      state.date = null;
      state.error = action.payload.message;
    });
  },
});

export default sendAnswersSlicers.reducer;
