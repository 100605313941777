import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { H } from "highlight.run";

export const getDiplomesDataFromApi = createAsyncThunk(
  "getAllDiplomes",
  async () => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/student/diplome/getAll`
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
        //H.consumeError(error,"this is test error mes");
        return error;
      });
    return request;
  }
);

const getAllDiplomesSlicer = createSlice({
  name: "diplome/getAll",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getDiplomesDataFromApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDiplomesDataFromApi.fulfilled, (state, action) => {
      state.loading = false;
      state.date = action.payload;
    });
    builder.addCase(getDiplomesDataFromApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getAllDiplomesSlicer.reducer;
