import { createSlice } from "@reduxjs/toolkit";

const ShowFeedbackFormSlicer = createSlice({
  name: "feedbackform/show",
  initialState: {
    showFeedbackFrom: false,
  },
  reducers: {
    hideFeedbackFormFunc: (state) => {
      state.showFeedbackFrom = false;
    },
    showFeedbackformFunc: (state) => {
      state.showFeedbackFrom = true;
    },
  },
});

export const { showFeedbackformFunc,hideFeedbackFormFunc } = ShowFeedbackFormSlicer.actions;
export default ShowFeedbackFormSlicer.reducer;
