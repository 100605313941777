import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { H } from "highlight.run";

export const getSingleJobFunc = createAsyncThunk(
  "getJob",
  async (jobId) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/student/carrers/${jobId}`
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
        //H.consumeError(error,"this is test error mes");
        return error;
      });
    return request;
  }
);

const getSingleJobSlicer = createSlice({
  name: "jobs/getSingle",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getSingleJobFunc.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getSingleJobFunc.fulfilled, (state, action) => {
      state.loading = false;
      state.date = action.payload;
    });
    builder.addCase(getSingleJobFunc.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export default getSingleJobSlicer.reducer;
