import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const resetPassworddApi = createAsyncThunk(
  "resetpassword",
  async (userCredintianals) => {
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/resetPassword/${userCredintianals.userid}`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          "newPassword": userCredintianals.newPassword
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => error);

    return request;
  }
);

const resetPasswordSlicer = createSlice({
  name: "users/reset-password",
  initialState: {
    loading: false,
    res: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(resetPassworddApi.pending,(state)=>{
        state.loading = true;
        state.res = null;
        state.error = null

    });
    builder.addCase(resetPassworddApi.fulfilled,(state,action)=>{
      if (action.payload.message === 'Password updated Successfully') {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
        } else {
            state.loading = false;
            state.res = null;
            state.error = action.payload.message;
            
        }
        console.log(action.payload);


    });
    builder.addCase(resetPassworddApi.rejected,(state,action)=>{
        state.loading = false;
        state.res = null;
        state.error = action.payload.message;

    });
  },
});

export default resetPasswordSlicer.reducer;
