import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const updatePersonalInfoApi = createAsyncThunk(
  "updatepersonalinfo",
  async (personalinfo) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/update-personal-info`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${tokenData}`,
        },
        body: JSON.stringify({
          name: personalinfo.name,
          mobile: personalinfo.mobile,
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => result)
      .catch((error) => error);

    return request;
  }
);

const updatePersonalInfoSlicer = createSlice({
  name: "profile/updatePersonalInfo",
  initialState: {
    loading: false,
    response: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(updatePersonalInfoApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(updatePersonalInfoApi.fulfilled, (state, action) => {
      state.loading = false;
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message === "Data Updated Successfully"
      ) {
        state.response = action.payload;
      }
      if (
        action.payload &&
        action.payload.message &&
        action.payload.message === "Invalid user data"
      ) {
        state.error = action.payload.data[0].msg;
      } 
    });
    builder.addCase(updatePersonalInfoApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error
      
    });
  },
});

export default updatePersonalInfoSlicer.reducer;
