import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const joinApi = createAsyncThunk(
  "joinApi",

  async (userFormData) => {
    return await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/users/contact_join/create_join_us`,
      {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
                name : userFormData.name,
                email : userFormData.email,
                phone : userFormData.phone,
                education : userFormData.education,
                description : userFormData.description
        }),
      }
    )
      .then((response) => response.json())
      .then((result) => {
       
        return result;
      })
      .catch((error) => {
      
        return error;
      });
  }
);

const joinInstrsctorFormSclicer = createSlice({
  name: "form/join-create",
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(joinApi.pending, (state) => {
      state.loading = true;
      state.data = null;
      state.error = null;
    });
    builder.addCase(joinApi.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.message === "Data sent successfully") {
        state.data = action.payload;
        state.error = null;
      } else {
        state.data = null;
        state.error = action.payload;
      }
    });
    builder.addCase(joinApi.rejected, (state, action) => {
      state.loading = false;
      state.data = null;
      state.error = action.payload;
    });
  },
});

export default joinInstrsctorFormSclicer.reducer;
