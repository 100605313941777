import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { H } from "highlight.run";

export const checkCertificateApi = createAsyncThunk(
  "checkCertificate",
  async (courseId) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/certificate/${courseId}`,
      {
        method: "Get",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        return resulet;
      })
      .catch((error) => {
       // H.consumeError(error, error.message);
        return error;
      });
    return request;
  }
);

const CertificateSlicer = createSlice({
  name: "certificate/check",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(checkCertificateApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(checkCertificateApi.fulfilled, (state, action) => {
      state.loading = false;
      state.date = action.payload;
    });
    builder.addCase(checkCertificateApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
  },
});

export default CertificateSlicer.reducer;
