import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const sendCompletingLessonFunc = createAsyncThunk(
  "complete-lesson",
  async (id) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;
    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/lessons/update-progress/${id}`,
      {
        method: "POST",
        headers: { 
            Authorization: `Bearer ${tokenData}`,
            "Content-Type": "application/json" 
        },
        // body: JSON.stringify({}),
      }
    )
      .then((response) => response.json())
      .then((result) => {
        return result;
      })
      .catch((error) => error);

    return request;
  }
);

const sendCompletingLessonSlicer = createSlice({
  name: "users/complete-lesson",
  initialState: {
    loading: false,
    res: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(sendCompletingLessonFunc.pending,(state)=>{
        state.loading = true;
        state.res = null;
        state.error = null

    });
    builder.addCase(sendCompletingLessonFunc.fulfilled,(state,action)=>{
      if (action.payload.message) {
        state.loading = false;
        state.user = action.payload;
        state.error = null;
        } else {
            state.loading = false;
            state.res = null;
            state.error = action.payload.message;
            
        }
        console.log(action.payload);


    });
    builder.addCase(sendCompletingLessonFunc.rejected,(state,action)=>{
        state.loading = false;
        state.res = null;
        state.error = action.payload.message;

    });
  },
});

export default sendCompletingLessonSlicer.reducer;
