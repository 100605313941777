import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getAllConsultantsFunc = createAsyncThunk('getAllConsultants',
async()=>{
    const request = await fetch(
        `${process.env.REACT_APP_VERSEL_API}/api/students/users/consultants`
      )
        .then((response) => response.json())
        .then((resulet) => {
          return resulet;
        })
        .catch((error) => {
          return error;
        });
      return request;
})

  


const getAllConsultantsSlicer = createSlice({
    name : 'users/consultants/getAll',
    initialState:{
        loading :false,
        data : null,
        error:null
    },
    extraReducers: (builder) => {
        builder.addCase(getAllConsultantsFunc.pending, (state) => {
          state.loading = true;
          state.date = null;
          state.error = null;
        });
        builder.addCase(getAllConsultantsFunc.fulfilled, (state, action) => {
          state.loading = false;
          if (action.payload.message === "fetched successfully") {
            console.log(action.payload)
            state.date = action.payload.data;
            state.error = null;
          } else {
            state.date = null;
            state.error = action.payload.message;
          }
        });
        builder.addCase(getAllConsultantsFunc.rejected, (state, action) => {
          state.loading = false;
          state.date = null;
          state.error = action.payload.message;
        });
      },
});

export default getAllConsultantsSlicer.reducer
