import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import LogoutFunction from "../../services/LogoutFunction";

export const getQuestionByQuizeId = createAsyncThunk(
  "getQuestions",
  async (quizeId) => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/quize/startExam/${quizeId}`,
      {
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((resulet) => {
        console.log(resulet);
        return resulet;
      })
      .catch((error) => {
        console.log(error);
        return error;
      });
    return request;
  }
);

const getQuestionQuizeDataSlicer = createSlice({
  name: "quize/satrt",
  initialState: {
    loading: false,
    date: null,
    error: null,
  },
  extraReducers: (builder) => {
    builder.addCase(getQuestionByQuizeId.pending, (state) => {
      state.loading = true;
      state.date = null;
      state.error = null;
    });
    builder.addCase(getQuestionByQuizeId.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.questions != []) {
        state.date = action.payload.data;
      } else {
        state.error = action.payload;
      }
    });
    builder.addCase(getQuestionByQuizeId.rejected, (state, action) => {
      state.loading = false;
      state.date = null;
      state.error = action.payload;
    });
  },
});

export default getQuestionQuizeDataSlicer.reducer;
