import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

export const getStudentDataApi = createAsyncThunk(
  "getStudentData",
  async () => {
    const tokenData = JSON.parse(localStorage.getItem("userData")).token;

    const request = await fetch(
      `${process.env.REACT_APP_VERSEL_API}/api/students/`,
      {
        method: "GET",
        headers: {
          Authorization: `Bearer ${tokenData}`,
        },
      }
    )
      .then((response) => response.json())
      .then((resulte) => {
        console.log(resulte);
        return resulte;
      })
      .catch((error) => {
        return error;
      });

    return request;
  }
);

const getStudentDataSlice = createSlice({
  name: "student/getstudentbyid",
  initialState: {
    loading: false,
    respose: null,
    error: null,
  },

  extraReducers: (builder) => {
    builder.addCase(getStudentDataApi.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getStudentDataApi.fulfilled, (state, action) => {
      state.loading = false;
      state.respose = action.payload.data;
    });
    builder.addCase(getStudentDataApi.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default getStudentDataSlice.reducer;
